import React from "react";
import { Helmet } from "react-helmet";
import AboutUsAvatar from "../components/AboutUsAvatar";

const QAPersonnel = () => {
  return (
    <>
      <Helmet>
        <title>
          QA Related Personnel - UP Mindanao Quality Assurance Office
        </title>
        <meta
          name="description"
          content="Explore QA-related personnel at the UP Mindanao Quality Assurance Office. Meet the team driving honor, excellence, and service through quality initiatives."
        />
        <link
          rel="canonical"
          href={`${process.env.REACT_APP_BASE_URL}/about-us`}
        />
      </Helmet>
      {/* EQA Reviewers */}
      <div className="p-4 sm:p-8">
        <div className="flex items-center gap-2 sm:gap-4">
          <hr className="border-secondary border-2 rounded-md flex-grow" />
          <h1 className="text-3xl text-center">EQA Reviewers</h1>
          <hr className="border-secondary border-2 rounded-md flex-grow" />
        </div>
        {/* Pictures */}
        <div className="sm:grid sm:grid-cols-2">
          <AboutUsAvatar name={"Asst. Prof. Roxanne T. Aguinaldo"} />
          <AboutUsAvatar name={"Asst. Prof. Melissa Claire I. Barrera-Yap"} />
          <AboutUsAvatar name={"Assoc. Prof. Jonathan Y. Cagas, PhD"} />
          <AboutUsAvatar name={"Prof. Karen Joyce G. Cayamanda, PhD"} />
          <AboutUsAvatar name={"Asst. Prof. Merle Dawn S. Comidoy-Acol"} />
          <AboutUsAvatar name={"Asst. Prof. Marbie A. Entero, MSc"} />
          <AboutUsAvatar name={"Asst. Prof. Edward G. Estrera"} />
          <AboutUsAvatar name={"Assoc. Prof. Ritchie Mae T. Gamot, PhD"} />
          <AboutUsAvatar name={"Assoc. Prof. Giovanna Fae R. Oguis, PhD"} />
          <AboutUsAvatar name={"Asst. Prof. Kristin Faye M. Olalo"} />
          <AboutUsAvatar name={"Assoc. Prof. Dennis John F. Sumaylo, PhD"} />
          <AboutUsAvatar name={"Asst. Prof. Myrafe S. Ylagan"} />
        </div>
      </div>

      {/* AUN-QA Assessors */}
      <div className="p-4 sm:p-8">
        <div className="flex items-center gap-2 sm:gap-4">
          <hr className="border-secondary border-2 rounded-md flex-grow" />
          <h1 className="text-3xl text-center">AUN-QA Assessors</h1>
          <hr className="border-secondary border-2 rounded-md flex-grow" />
        </div>
        {/* Pictures */}
        <div className="sm:grid sm:grid-cols-2">
          <AboutUsAvatar name={"Assoc. Prof. Ritchie Mae T. Gamot, PhD"} />
          <AboutUsAvatar
            name={"Assoc. Prof. Aurelia Luzviminda V. Gomez, PhD"}
          />
          <AboutUsAvatar name={"Prof. Lyre Anni E. Murao, PhD"} />
          <AboutUsAvatar name={"Prof. Annabelle U. Novero, PhD"} />
        </div>
      </div>
    </>
  );
};

export default QAPersonnel;
