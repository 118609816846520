import React from "react";

export const jsonToPrettyStr = (json) => {
  return (
    <div>
      {Object.entries(json).map(([key, value]) => (
        <div key={key}>
          <strong>{key}:</strong> {value}
        </div>
      ))}
    </div>
  );
};
