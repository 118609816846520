import React from "react";
import filler from "../assets/UPMin_QAO.png";

const AboutUsAvatar = ({ name, title, image = filler, big = false }) => {
  return (
    <div className="flex flex-col items-center p-4 animate-fade-up">
      <img
        src={image}
        alt={name}
        loading="lazy"
        className={
          big
            ? "size-[192px] lg:size-[256px] rounded-full object-cover shadow-2xl outline outline-secondary"
            : "size-[128px] lg:size-[192px] rounded-full object-cover shadow-2xl outline outline-secondary"
        }
      />
      <h1 className="font-bold text-center text-lg pt-2">{name}</h1>
      <h2 className="text-center text-lg">{title}</h2>
    </div>
  );
};

export default AboutUsAvatar;
