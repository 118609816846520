export const orderImages = (images, image_order) => {
  let ordered_images = [];
  let original_images = [...images];
  if (image_order) {
    image_order.forEach((element) => {
      const curr_img = original_images.findIndex(
        (image) => element === image.id
      );
      if (curr_img !== -1) {
        ordered_images.push(original_images[curr_img]);
        original_images.splice(curr_img, 1);
      }
    });
  }
  ordered_images.push(...original_images);
  return ordered_images;
};
