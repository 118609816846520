import React from "react";
import DOMPurify from "dompurify";

const PostContentComponent = ({ postHtml, isPreview }) => {
  // Configure DOMPurify to allow specific tags
  const allowedTags = [
    "b",
    "strong",
    "i",
    "em",
    "u",
    "ol",
    "ul",
    "li",
    "a",
    "img",
    "p",
    "div",
    "br",
  ];
  const allowedAttributes = ["href", "target", "src", "alt", "style", "class"];

  // Sanitize the HTML to only allow the specific tags and attributes
  const cleanHtml = DOMPurify.sanitize(postHtml, {
    ALLOWED_TAGS: allowedTags,
    ALLOWED_ATTR: allowedAttributes,
  });

  let htmlToRender = cleanHtml;

  if (isPreview) {
    // Remove <img> tags in preview mode
    htmlToRender = htmlToRender.replace(/<img[^>]*>/g, "");

    // Extract only the first <p> tag
    const firstPMatch = htmlToRender.match(/<p[^>]*>(.*?)<\/p>/);
    if (firstPMatch) {
      htmlToRender = firstPMatch[0]; // Use the first <p> tag found
    } else {
      htmlToRender = ""; // If no <p> tag is found, render empty
    }
  }

  return isPreview ? (
    <p
      className="px-2 mb-2 line-clamp-6"
      dangerouslySetInnerHTML={{ __html: htmlToRender }}
    />
  ) : (
    <div
      className="postHTML py-2 px-4 sm:px-8 lg:px-16"
      dangerouslySetInnerHTML={{
        __html: htmlToRender,
      }}
    />
  );
};

export default PostContentComponent;
