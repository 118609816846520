import React, { useState } from "react";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/plugins/captions.css";
import { Helmet } from "react-helmet";
import AboutUsAvatar from "../components/AboutUsAvatar";

// import heroImage from "../assets/qao_door.jpg";
import { Splide, SplideSlide } from "@splidejs/react-splide";
// import heroImage from "../assets/qao_team.jpg";
import aabuenaobra from "../assets/staff-img/aabuenaobra.jpg";
import rlyu from "../assets/staff-img/rlyu.jpg";
import capoledo from "../assets/staff-img/capoledo.jpg";
import tgang from "../assets/staff-img/tgang.jpg";
import rtgamot from "../assets/staff-img/rtgamot.jpg";
import qualityPolicy from "../assets/up_quality_policy.png";

const AboutUs = () => {
  const [open, setOpen] = useState(false);

  const folderImages = require.context("../assets/group-photos", false);
  const groupImgs = [];
  folderImages.keys().forEach((key) => {
    groupImgs.push(folderImages(key));
  });

  return (
    <>
      <Helmet>
        <title>About Us - UP Mindanao Quality Assurance Office</title>
        <meta
          name="description"
          content="Learn about the UP Mindanao Quality Assurance Office. Our mission, vision, and commitment to advancing honor, excellence, and service in education."
        />
        <link
          rel="canonical"
          href={`${process.env.REACT_APP_BASE_URL}/about-us`}
        />
      </Helmet>

      {/* Hero Section */}
      <div className="flex flex-col shadow-xl animate-fade">
        {/* <img
          src={heroImage}
          alt="Door to QAO"
          className="object-contain w-screen"
        /> */}
        {/* <div className="h-full bg-gradient-to-br to-secondary/30 from-primary/30 z-10" /> */}
        {groupImgs.length > 0 && (
          <Splide
            aria-label="Banner Images"
            options={{
              type: "fade",
              rewind: true,
              heightRatio: 9 / 16,
              autoplay: true,
              arrows: false,
              pagination: false,
              // lazyLoad: "sequential",
            }}
            className="animate-fade"
          >
            {groupImgs.map((image, i) => {
              return (
                <SplideSlide key={i}>
                  <img
                    src={image}
                    // data-splide-lazy={image}
                    alt={image}
                    className="object-cover mx-auto h-full"
                  />
                </SplideSlide>
              );
            })}
          </Splide>
        )}
      </div>
      <div className="mx-auto lg:w-[70%] shadow-2xl">
        <img
          src={qualityPolicy}
          alt="UP Mindanao Quality Policy"
          className="p-4 sm:p-8"
          loading="lazy"
          onClick={() => setOpen(true)}
        />
        <Lightbox
          open={open}
          close={() => {
            setOpen(false);
          }}
          slides={[{ src: qualityPolicy, title: "UP Quality Policy" }]}
          animation={{ fade: 0 }}
          controller={{ closeOnBackdropClick: true, closeOnPullDown: true }}
          plugins={[Captions, Zoom]}
        />

        {/* About UP QAO */}
        <div className="p-4 sm:p-8">
          <div className="flex items-center gap-2 sm:gap-4">
            <hr className="border-secondary border-2 rounded-md flex-grow" />
            <h1 className="text-2xl text-center w-60 sm:w-auto sm:text-3xl">
              UP Mindanao Quality Assurance Office
            </h1>
            <hr className="border-secondary border-2 rounded-md flex-grow" />
          </div>
          <p className="pt-1">
            UP Mindanao is the only UP constituent university in Mindanao with
            the primary mandate to provide equitable access to quality UP
            education to Mindanaoans. It offers academic programs in the fields
            of the humanities, architecture, mathematics, food, economics,
            social, natural, and computing sciences. As a graduate university,
            it offers PhD by Research, and graduate degrees in management, food
            science, urban and regional planning, and sports science. Its
            development agenda focuses on opening additional academic programs
            and new colleges in human kinetics, medicine, and engineering, to
            support Mindanao sports, health, technological, and bio-cultural
            diversity initiatives. With these, it aims to be an internationally
            competitive graduate, research, and public service university,
            primed to contribute to Philippine development.
          </p>
        </div>

        {/* Vision*/}
        <div className="p-4 sm:p-8">
          <div className="flex items-center gap-2 sm:gap-4">
            <hr className="border-secondary border-2 rounded-md flex-grow" />
            <h1 className="text-3xl text-center">Vision</h1>
            <hr className="border-secondary border-2 rounded-md flex-grow" />
          </div>
          <p className="pt-1">
            A transformative university at the forefront of 21st-century
            innovation in education, research, creative works, and public
            service by 2025.
          </p>
        </div>

        {/* Mission*/}
        <div className="p-4 sm:p-8">
          <div className="flex items-center gap-2 sm:gap-4">
            <hr className="border-secondary border-2 rounded-md flex-grow" />
            <h1 className="text-3xl text-center">Mission</h1>
            <hr className="border-secondary border-2 rounded-md flex-grow" />
          </div>
          <p className="pt-1">
            Our mission is to provide education, research capability, creative
            environment, and public engagement that transform lifelong learners
            into responsive leaders advancing culturally sensitive solutions to
            issues of rapidly changing times.
          </p>
        </div>

        {/* The Team */}
        <div className="p-4 sm:p-8">
          <div className="flex items-center gap-2 sm:gap-4">
            <hr className="border-secondary border-2 rounded-md flex-grow" />
            <h1 className="text-3xl text-center">The Team</h1>
            <hr className="border-secondary border-2 rounded-md flex-grow" />
          </div>
          {/* Pictures */}
          <AboutUsAvatar
            name={"Assoc. Prof. Ritchie Mae T. Gamot, PhD"}
            title={"QAO Director"}
            image={rtgamot}
            big={true}
          />
          <AboutUsAvatar
            name={"Theressa G. Ang"}
            title={"Administrative Officer 1"}
            image={tgang}
          />
          <div className="sm:flex justify-evenly">
            <AboutUsAvatar
              name={"Rozhelle Sophia L. Yu"}
              title={"Senior Office Aide"}
              image={rlyu}
            />
            <AboutUsAvatar
              name={"Andrea Trisha A. Buenaobra"}
              title={"Senior Office Aide"}
              image={aabuenaobra}
            />
          </div>

          <AboutUsAvatar
            name={"Clent Japhet A. Poledo"}
            title={"Junior ICT Assistant"}
            image={capoledo}
          />
        </div>
      </div>
    </>
  );
};

export default AboutUs;
