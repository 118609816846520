import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { CgMenuBoxed, CgClose } from "react-icons/cg";

// import qaoLogo from "../assets/QAO_Logo.png";
import qaoLogo from "../assets/UPMin_QAO.png";
import api, { apiErrAlert } from "../services/api";

const NavBar = ({ currentUser, getCurrentUser }) => {
  const [sideNav, setSideNav] = useState(false);
  const [aboutDrp, setAboutDrp] = useState(false);

  useEffect(() => {
    getCurrentUser();
  }, [getCurrentUser]);

  const logout = () => {
    api
      .post("/logout/")
      .then((res) => {
        localStorage.removeItem("token");
        window.location.reload();
      })
      .catch((err) => {
        apiErrAlert(err);
      });
  };

  return (
    <>
      {currentUser !== "" && (
        <div className="flex justify-end px-2 pb-1 gap-2 bg-primary text-white border-b-2">
          <p>WELCOME, {currentUser}.</p>{" "}
          <button className="underline hover:no-underline">
            <Link to="/change-password">CHANGE PASSWORD</Link>
          </button>
          <button className="underline hover:no-underline" onClick={logout}>
            LOG OUT
          </button>
        </div>
      )}
      <nav className="flex justify-between px-4 py-2 sm:py-4 items-center shadow-xl bg-primary text-white">
        <Link to={`/`}>
          <div className="flex justify-between gap-2 items-center">
            <img src={qaoLogo} alt="QAO Logo" className="h-20 rounded-full" />
            <div className="hidden sm:block flex-col">
              <p className="text-lg">University of the Philippines Mindanao</p>
              <hr className="border-black" />
              <h1 className="text-3xl">Quality Assurance Office</h1>
            </div>
          </div>
        </Link>
        <CgMenuBoxed
          size={56}
          className="hover:text-secondary cursor-pointer lg:hidden"
          onClick={() => setSideNav(true)}
        />
        <ul className="hidden lg:flex justify-between text-2xl">
          <li>
            <Link
              to={`/`}
              className="hover:bg-secondary rounded-xl p-4 inline-block"
            >
              Home
            </Link>
          </li>
          <li>
            <Link
              to={`/news`}
              className="hover:bg-secondary rounded-xl p-4 inline-block"
            >
              News
            </Link>
          </li>
          <li>
            <Link
              to={`/events`}
              className="hover:bg-secondary rounded-xl p-4 inline-block"
            >
              Events
            </Link>
          </li>
          <li className="relative" onMouseLeave={() => setAboutDrp(false)}>
            <button
              className="hover:bg-secondary rounded-lg p-4 w-full"
              onClick={() => setAboutDrp(!aboutDrp)}
              onMouseOver={() => setAboutDrp(true)}
            >
              About Us
            </button>
            <ul
              className={
                aboutDrp
                  ? "-mt-1 absolute w-60 p-1 text-sm border flex flex-col items-center opacity-100 scale-95 duration-100 bg-primary z-10 rounded-lg"
                  : "absolute w-40 p-1 text-sm border flex flex-col items-center opacity-0 scale-95 duration-100 bg-primary z-10 rounded-lg"
              }
            >
              <li>
                <Link
                  to={`/about-us`}
                  className="hover:bg-secondary rounded-xl p-4 inline-block"
                  onClick={() => setSideNav(false)}
                >
                  About UPMin QAO
                </Link>
              </li>
              <li>
                <Link
                  to={`/qa-personnel`}
                  className="hover:bg-secondary rounded-xl p-4 inline-block"
                  onClick={() => setSideNav(false)}
                >
                  QA Related Personnel
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <Link
              to={`/contact-us`}
              className="hover:bg-secondary rounded-xl p-4 inline-block"
            >
              Contact Us
            </Link>
          </li>
        </ul>

        <div
          className={
            sideNav
              ? "fixed lg:hidden top-0 left-0 w-screen h-screen bg-primary opacity-50 z-20"
              : "hidden top-0 left-0 w-screen h-screen bg-primary opacity-50 z-20"
          }
          onClick={() => setSideNav(false)}
        />

        {/* Side Navigation */}
        <div
          className={
            sideNav
              ? "fixed lg:hidden top-0 right-0 w-60 h-screen bg-primary z-20 pt-20 px-4 duration-200 shadow-xl"
              : "fixed top-0 right-[-100%] w-60 h-screen bg-primary z-20 pt-20 px-4 duration-200 shadow-xl"
          }
        >
          <CgClose
            size={32}
            className="absolute top-8 right-8 cursor-pointer hover:text-secondary"
            onClick={() => setSideNav(false)}
          />
          <ul className="flex-col justify-between text-2xl">
            <li>
              <Link to={`/`}>
                <button
                  className="hover:bg-secondary rounded-lg p-4 w-full"
                  onClick={() => setSideNav(false)}
                >
                  Home
                </button>
              </Link>
            </li>
            <li>
              <Link to={`/news`}>
                <button
                  className="hover:bg-secondary rounded-lg p-4 w-full"
                  onClick={() => setSideNav(false)}
                >
                  News
                </button>
              </Link>
            </li>
            <li>
              <Link to={`/events`}>
                <button
                  className="hover:bg-secondary rounded-lg p-4 w-full"
                  onClick={() => setSideNav(false)}
                >
                  Events
                </button>
              </Link>
            </li>
            <li className="relative">
              <button
                className="hover:bg-secondary rounded-lg p-4 w-full"
                onClick={() => setAboutDrp(!aboutDrp)}
              >
                About Us
              </button>
              <ul
                className={
                  aboutDrp
                    ? "relative w-full text-sm border-y flex flex-col items-center opacity-100 scale-95 duration-100 bg-primary"
                    : "absolute w-full text-sm border-y flex flex-col items-center opacity-0 scale-95 duration-100 bg-primary"
                }
              >
                <li>
                  <Link
                    to={`/about-us`}
                    className="hover:bg-secondary rounded-xl p-4 inline-block"
                    onClick={() => setSideNav(false)}
                  >
                    About UPMin QAO
                  </Link>
                </li>
                <li>
                  <Link
                    to={`/qa-personnel`}
                    className="hover:bg-secondary rounded-xl p-4 inline-block"
                    onClick={() => setSideNav(false)}
                  >
                    QA Related Personnel
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to={`/contact-us`}>
                <button
                  className="hover:bg-secondary rounded-lg p-4 w-full"
                  onClick={() => setSideNav(false)}
                >
                  Contact Us
                </button>
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default NavBar;
