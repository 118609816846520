export const dateToString = (date) => {
  const unformatted = new Date(date);
  const formattedDate = {};

  formattedDate.date = unformatted.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  formattedDate.weekday = unformatted.toLocaleDateString("en-US", {
    weekday: "long",
  });

  formattedDate.time = unformatted.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  return formattedDate;
};

export const isoToLocal = (isoDate) => {
  // from: 2024-11-01T06:00:00+08:00 (django)
  // to: 2024-11-01T06:00 (HTML form)
  const date = new Date(isoDate);
  // Adjust from UTC to +8 timezone
  const offsetDate = new Date(date.getTime() + 8 * 60 * 60 * 1000); // Add 8 hours in milliseconds
  return offsetDate.toISOString().slice(0, 16); // Removes the seconds and timezone
};

export const localToIso = (localDate) => {
  // from: 2024-11-01T06:00 (HTML form)
  // to: 2024-11-01T06:00 (HTML form)
  const date = new Date(localDate);
  // Adjust the time back to UTC+8
  const offsetDate = new Date(date.getTime() + 8 * 60 * 60 * 1000); // Add 8 hours in milliseconds
  return offsetDate.toISOString().replace(".000Z", "+08:00"); // Format with the +08:00 timezone
};
