import React from "react";
import { TbError404 } from "react-icons/tb";

const Page404 = () => {
  return (
    <div className="p-16 text-center">
      <h1 className="font-bold text-7xl px-8">Error</h1>
      <TbError404 size={128} className="mx-auto"/>
      <h2 className="text-2xl p-8">
        The requested page is not found in the server.
      </h2>
    </div>
  );
};

export default Page404;
