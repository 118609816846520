import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import api, { apiErrAlert } from "../services/api";
import { PuffLoader } from "react-spinners";
import { dateToString } from "../utils/dateUtils";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Inline from "yet-another-react-lightbox/plugins/inline";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/plugins/captions.css";

import Page404 from "./Page404";
import PostContentComponent from "../components/PostContentComponent";
import { FaEdit } from "react-icons/fa";
import { orderImages } from "../utils/imageOrderUtils";
import { Helmet } from "react-helmet";
import { stripHTMLTags } from "../utils/htmlUtils";
import NewsCard from "../components/NewsCard";
import filler from "../assets/QAO_Logo.png";
import NumberNav from "../components/NumberNav";
import { FaFacebook, FaLink } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { toast } from "react-toastify";

const PostContent = ({ postData, currentUser }) => {
  const stringDateCreated = dateToString(postData.created);
  const stringDateLastEdited = dateToString(postData.last_edited);
  const bannerImages = postData.images[0]
    ? orderImages(postData.images, postData.image_order)
    : false;

  const [open, setOpen] = useState(false);
  const [index, setIndex] = useState(0);
  const slides = bannerImages
    ? bannerImages.map((image) => {
        return { src: image.image };
      })
    : [];
  const updateIndex = ({ index }) => {
    setIndex(index);
  };
  const copyToClipboard = () => {
    navigator.clipboard.writeText(window.location.href);
    toast.info("Link copied to clipboard!");
  };

  return (
    <div className="pb-2 mx-auto lg:w-[70%] shadow-2xl lg:rounded-xl">
      <Helmet>
        <title>{postData.title}</title>
        <meta
          name="description"
          content={stripHTMLTags(postData.content).slice(0, 160)}
        />
        <link
          rel="canonical"
          href={`${process.env.REACT_APP_BASE_URL}/post/${postData.id}`}
        />
      </Helmet>

      {bannerImages && (
        <Lightbox
          slides={slides}
          index={index}
          on={{
            view: updateIndex,
            click: () => {
              setOpen(true);
            },
          }}
          inline={{
            style: {
              width: "100%",
              minHeight: "384px",
              aspectRatio: "6 / 5",
              margin: "0 auto",
            },
          }}
          thumbnails={{ vignette: false }}
          carousel={{ padding: 0, spacing: 0, finite: true }}
          plugins={[Inline, Thumbnails]}
          styles={{
            container: { backgroundColor: "rgb(243 244 246)" },
            thumbnailsContainer: { backgroundColor: "rgb(243 244 246)" },
            thumbnail: { backgroundColor: "rgb(255 255 255)" },
          }}
        />
      )}
      <Lightbox
        open={open}
        close={() => {
          setOpen(false);
        }}
        index={index}
        slides={slides}
        on={{ view: updateIndex }}
        animation={{ fade: 0 }}
        carousel={{ finite: true }}
        controller={{ closeOnBackdropClick: true, closeOnPullDown: true }}
        plugins={[Captions, Zoom]}
      />
      <h1 className="flex gap-4 justify-center items-center text-3xl py-2 lg:pt-8 px-4 sm:px-8 lg:px-16 text-center">
        {postData.title}
        {postData.owner === currentUser && (
          <Link to={`/post/edit/${postData.id}`}>
            <button className="hover:text-secondary">
              <FaEdit className="min-w-9" />
            </button>
          </Link>
        )}
      </h1>
      {postData.tags && (
        <div className="flex gap-1 items-center justify-center px-4 sm:px-8 lg:px-16">
          {postData.tags.map((tag) => (
            <p
              key={tag.id}
              className="text-sm bg-primary w-fit text-white py-1 px-2 rounded-full"
            >
              {tag.name}
            </p>
          ))}
        </div>
      )}
      {postData.authors && (
        <h2 className="text-sm text-center px-4 sm:px-8 lg:px-16">
          Author{postData.authors[1] && "s"}:{" "}
          {postData.authors.map((author) => author.full_name).join(", ")}
        </h2>
      )}
      {postData.image_authors[0] && (
        <h2 className="text-sm text-center px-4 sm:px-8 lg:px-16">
          Image Author{postData.image_authors[1] && "s"}:{" "}
          {postData.image_authors.map((author) => author.full_name).join(", ")}
        </h2>
      )}
      <h3 className="text-sm text-center lg:pb-4 px-4 sm:px-8 lg:px-16">
        Posted {stringDateCreated.date} | Last Edited{" "}
        {stringDateLastEdited.date}
      </h3>
      {/* <div
        className="postHTML py-2 px-8 lg:px-16"
        dangerouslySetInnerHTML={{
          __html: postData.content,
        }}
      /> */}
      <PostContentComponent postHtml={postData.content} isPreview={false} />

      <div className="flex gap-4 pt-8 lg:pb-4 px-2 sm:px-4 lg:px-8">
        <h3 className="text-xl">Share this post: </h3>
        <a
          href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
            window.location.href
          )}`}
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center gap-2 bg-primary text-white py-2 px-4 rounded-full hover:bg-secondary"
        >
          <FaFacebook />
        </a>
        <a
          href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(
            window.location.href
          )}&text=${encodeURIComponent(postData.title)}`}
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center gap-2 bg-primary text-white py-2 px-4 rounded-full hover:bg-secondary"
        >
          <FaXTwitter />
        </a>
        <button
          onClick={copyToClipboard}
          className="flex items-center gap-2 bg-primary text-white py-2 px-4 rounded-full hover:bg-secondary cursor-pointer"
        >
          <FaLink />
        </button>
      </div>
    </div>
  );
};

const Post = ({ currentUser }) => {
  const { id } = useParams();
  const [postData, setPostData] = useState({});
  const [resCode, setResCode] = useState(0);
  const [news, setNews] = useState([]);
  const [newsLoading, setNewsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    api
      .get(`/post/${id}`)
      .then((res) => {
        setPostData(res.data);
        setResCode(res.status);
      })
      .catch((err) => {
        err.status !== 404 && apiErrAlert(err);
        setResCode(err.status);
      });
  }, [id]);

  useEffect(() => {
    api
      .get(`/posts/?page_size=3&page=${page}`)
      .then((res) => {
        setNews(res.data.results);
        setNewsLoading(false);
        setTotalPages(res.data.total_pages);
      })
      .catch((err) => {
        apiErrAlert(err);
      });
  }, [page]);

  return (
    <div>
      {resCode === 200 ? (
        <>
          <PostContent postData={postData} currentUser={currentUser} />
          <div className="p-4 sm:p-8">
            <div className="flex items-center gap-2 sm:gap-4 pb-4">
              <h1 className="text-4xl">More News</h1>
              <hr className="border-secondary border-2 rounded-md flex-grow" />
            </div>
            <div className="grid gap-4 sm:grid-cols-2 lg:grid-cols-3 lg:gap-8">
              {newsLoading ? (
                <PuffLoader
                  color="#014421"
                  className="m-auto col-span-3"
                  size={32}
                />
              ) : (
                news.map((post) => (
                  <NewsCard
                    key={post.id}
                    targetPage={`/post/${post.id}`}
                    bannerImg={
                      post.images[0]
                        ? orderImages(post.images, post.image_order)[0].image
                        : filler
                    }
                    title={post.title}
                    author={post.authors
                      .map((author) => author.full_name)
                      .join(", ")}
                    content={post.content}
                    date={post.created}
                  />
                ))
              )}
            </div>
            <NumberNav
              currentPage={page}
              totalPages={totalPages}
              setPageFn={setPage}
            />
          </div>
        </>
      ) : resCode === 404 ? (
        <Page404 />
      ) : (
        <PuffLoader color="#014421" className="mx-auto my-20" size={32} />
      )}
    </div>
  );
};

export default Post;
