import React, { useEffect, useRef, useState } from "react";
import { useParams, Navigate } from "react-router-dom";
import { BeatLoader, PuffLoader } from "react-spinners";
import { BiSolidImageAdd } from "react-icons/bi";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import ReactQuill from "react-quill";
import Select from "react-dropdown-select";
import "react-quill/dist/quill.snow.css";
import api, { apiErrAlert } from "../services/api";
import ImageSelector from "../components/ImageSelector";
import Page404 from "./Page404";
import { orderImages } from "../utils/imageOrderUtils";
import { toast } from "react-toastify";

const EditPostMain = ({ postData }) => {
  const [link, setLink] = useState("");
  const [isPublished, setIsPublished] = useState(false);
  const [title, setTitle] = useState(""); // required
  const [authors, setAuthors] = useState([]); //required
  const [imageAuthors, setImageAuthors] = useState([]);
  const [tags, setTags] = useState([]);
  const [images, setImages] = useState([]);
  const [content, setContent] = useState(""); // required

  const [allAuthors, setAllAuthors] = useState([]);
  const [fetchingAuthors, setFetchingAuthors] = useState(true);
  const [allTags, setAllTags] = useState([]);
  const [fetchingTags, setFetchingTags] = useState(true);

  const quillRef = useRef(null);
  const [submitting, setSubmitting] = useState(false);
  const [imgSelectorMode, setImgSelectorMode] = useState(null);
  const [initialImages, setInitialImages] = useState([]);
  const [startNavigate, setStartNavigate] = useState(false);

  const callApis = async () => {
    try {
      const resAuthors = await api.get("/authors/?all");
      setAllAuthors(resAuthors.data);
      setFetchingAuthors(false);
      const resTags = await api.get("/tags/?all");
      setAllTags(resTags.data);
      setFetchingTags(false);
    } catch (err) {
      apiErrAlert(err);
    }
  };

  useEffect(() => {
    if (Object.keys(postData).length > 0) {
      setLink(`/post/${postData.id}/`);
      setIsPublished(postData.is_published);
      setTitle(postData.title);
      setAuthors(postData.authors);
      setImageAuthors(postData.image_authors);
      setTags(postData.tags);
      setImages(orderImages(postData.images, postData.image_order));
      setContent(postData.content);
    }
    callApis();
  }, [postData]);

  const handleImageUpload = (mode) => {
    if (mode === "content") {
      setInitialImages([]);
    } else if (mode === "images") {
      setInitialImages(images);
    }
    setImgSelectorMode(mode);
  };
  const modules = useRef({
    toolbar: {
      container: [
        ["bold", "italic", "underline"], // Toggle buttons for bold, italic, underline
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        [{ list: "ordered" }, { list: "bullet" }], // Lists
        ["link", "image"], // Link and image options
      ],
      handlers: {
        image: () => handleImageUpload("content"),
      },
    },
  });
  const formats = [
    "bold",
    "italic",
    "underline",
    "list",
    "bullet",
    "link",
    "image",
    "align",
  ];

  const onImgSelectorSubmit = (chosenImages) => {
    switch (imgSelectorMode) {
      case "content":
        const quill = quillRef.current.getEditor();
        quill.focus();
        const range = quill.getSelection();
        let index = range ? range.index : quill.getLength();
        chosenImages.forEach((item) => {
          quill.insertEmbed(index, "image", item.image);
          index += 1;
        });
        break;

      case "images":
        setImages([...chosenImages]);
        break;

      default:
        break;
    }
    setImgSelectorMode(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    let allAuthorsCopy = [...allAuthors];
    let authorsCopy = [...authors];
    let imageAuthorsCopy = [...imageAuthors];
    let allTagsCopy = [...allTags];
    let tagsCopy = [...tags];
    try {
      const authorPromises = authors.map(async (author) => {
        if (typeof author.id !== "number") {
          const res = await api.post("/authors/", { full_name: author.id });
          allAuthorsCopy.push(res.data);
          authorsCopy = authorsCopy.map((item) => {
            if (item.id === author.id) {
              return res.data;
            }
            return item;
          });
          author.id = res.data.id;
        }
        return author.id;
      });
      const authorIds = await Promise.all(authorPromises);
      const imageAuthorPromises = imageAuthors.map(async (author) => {
        if (typeof author.id !== "number") {
          // check first if author was already posted from the author input
          const found = allAuthorsCopy.find(
            (item) => item.full_name === author.id
          );
          let data = {};
          if (found) {
            data = found;
          } else {
            const res = await api.post("/authors/", { full_name: author.id });
            allAuthorsCopy.push(res.data);
            data = res.data;
          }
          imageAuthorsCopy = imageAuthorsCopy.map((item) => {
            if (item.id === author.id) {
              return data;
            }
            return item;
          });
          author.id = data.id;
        }
        return author.id;
      });
      const imageAuthorIds = await Promise.all(imageAuthorPromises);
      const tagPromises = tags.map(async (tag) => {
        if (typeof tag.id !== "number") {
          const res = await api.post("/tags/", { name: tag.id });
          allTagsCopy.push(res.data);
          tagsCopy.map((item) => {
            if (item.id === tag.id) {
              return res.data;
            }
            return item;
          });
          tag.id = res.data.id;
        }
        return tag.id;
      });
      const tagIds = await Promise.all(tagPromises);
      const image_ids = images.map((image) => {
        return image.id;
      });

      const body = {
        title: title,
        content: content,
        is_published: isPublished,
        author_ids: authorIds,
        image_author_ids: imageAuthorIds,
        tag_ids: tagIds,
        image_ids: image_ids,
        image_order: image_ids,
      };

      if (Object.keys(postData).length > 0) {
        await api.put(`/post/${postData.id}/`, body);
      } else {
        const res = await api.post(`/posts/`, body);
        setLink(`/post/${res.data.id}/`);
      }
      toast.success("Successfully saved post.");
      setStartNavigate(true);
    } catch (err) {
      apiErrAlert(err);
    } finally {
      setSubmitting(false);
      setAllAuthors([...allAuthorsCopy]);
      setAuthors([...authorsCopy]);
      setImageAuthors([...imageAuthorsCopy]);
      setAllTags([...allTagsCopy]);
      setTags([...tagsCopy]);
    }
  };

  const handleDelete = () => {
    if (window.confirm("Are you sure you want to delete this post?")) {
      setSubmitting(true);
      api
        .delete(`/post/${postData.id}/`)
        .then((res) => {
          console.log(res.data);
          setLink("/news/");
          toast.success("Successfully deleted post.");
          setStartNavigate(true);
        })
        .catch((err) => {
          apiErrAlert(err);
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  };

  return (
    <div className="pb-8 mx-auto lg:w-[70%] shadow-2xl relative">
      {startNavigate && <Navigate to={link} />}
      {submitting && (
        <div className="absolute z-10 size-full bg-primary/50"></div>
      )}
      {imgSelectorMode && (
        <ImageSelector
          mode={setImgSelectorMode}
          resultHandler={onImgSelectorSubmit}
          initialImages={initialImages}
        />
      )}
      <form
        className="p-4"
        onSubmit={handleSubmit}
        onKeyDown={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
      >
        <h1 className="text-center text-3xl">
          {Object.keys(postData).length === 0 ? "Create" : "Update"} Post
        </h1>
        <div className="my-3 flex gap-1 items-center justify-center">
          <input
            type="checkbox"
            id="isPublished"
            required={false}
            checked={isPublished}
            onChange={(e) => setIsPublished(!isPublished)}
          />

          <label htmlFor="isPublished" className="text-secondary">
            Published
          </label>
        </div>
        <div className="relative z-0 my-3 w-full">
          <input
            type="text"
            id="title"
            className="block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-secondary peer"
            placeholder=" "
            required={true}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <label
            htmlFor="title"
            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 peer-focus:text-secondary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
          >
            Title <span className="text-red-500">*</span>
          </label>
        </div>
        <div className="my-3">
          <label htmlFor="authors" className="text-xs text-secondary">
            Authors <span className="text-red-500">*</span>
          </label>
          <Select
            options={allAuthors}
            values={authors}
            onChange={(value) => {
              setAuthors(value);
            }}
            valueField="id"
            labelField="full_name"
            searchBy="full_name"
            color="#014421"
            loading={fetchingAuthors}
            required
            multi
            create
            name="authors"
          />
        </div>
        <div className="my-3">
          <label htmlFor="imageAuthors" className="text-xs text-secondary">
            Image Authors
          </label>
          <Select
            options={allAuthors}
            values={imageAuthors}
            onChange={(value) => {
              setImageAuthors(value);
            }}
            valueField="id"
            labelField="full_name"
            searchBy="full_name"
            color="#014421"
            loading={fetchingAuthors}
            multi
            create
            name="imageAuthors"
          />
        </div>
        <div className="my-3">
          <label htmlFor="tags" className="text-xs text-secondary">
            Tags
          </label>
          <Select
            options={allTags}
            values={tags}
            onChange={(value) => {
              setTags(value);
            }}
            valueField="id"
            labelField="name"
            searchBy="name"
            color="#014421"
            loading={fetchingTags}
            multi
            create
            name="tags"
          />
        </div>

        <div className="my-3">
          <label htmlFor="images" className="text-xs text-secondary">
            Banner Images
          </label>
          <div className="grid gap-1 text-primary">
            <button
              type="button"
              className="p-4 hover:text-secondary size-fit mx-auto flex gap-1 items-center"
              onClick={() => {
                handleImageUpload("images");
              }}
            >
              <BiSolidImageAdd size={32} className="m-auto" />
              <p className="text-lg">Add Images</p>
            </button>
            {images.length > 0 && (
              <Splide
                aria-label="Banner Images"
                options={{
                  type: "fade",
                  rewind: true,
                  heightRatio: 9 / 16,
                  autoplay: true,
                }}
              >
                {images.map((image) => {
                  return (
                    <SplideSlide key={image.id}>
                      <img
                        src={image.image}
                        alt={image.image}
                        className="object-fill mx-auto h-full"
                      />
                    </SplideSlide>
                  );
                })}
              </Splide>
            )}
          </div>
        </div>

        <div className="my-3">
          <label className="text-xs text-secondary">
            Content <span className="text-red-500">*</span>
          </label>
          <ReactQuill
            ref={quillRef}
            theme="snow"
            value={content}
            onChange={setContent}
            modules={modules.current}
            formats={formats}
          />
        </div>

        <div className="flex justify-evenly">
          {Object.keys(postData).length !== 0 && (
            <button
              type="button"
              className="bg-primary hover:bg-secondary text-white font-bold p-4 rounded-xl disabled:bg-gray-400"
              disabled={submitting}
              value="save"
              onClick={handleDelete}
            >
              {submitting ? <BeatLoader className="m-auto" /> : "Delete"}
            </button>
          )}
          <button
            type="submit"
            className="bg-secondary hover:bg-primary text-white font-bold p-4 rounded-xl disabled:bg-gray-400"
            disabled={submitting}
            value="save"
          >
            {submitting ? <BeatLoader className="m-auto" /> : "Save"}
          </button>
        </div>
      </form>
    </div>
  );
};

const EditPost = ({ currentUser }) => {
  const { id } = useParams();
  const [postData, setPostData] = useState({});
  const [resCode, setResCode] = useState(0);

  useEffect(() => {
    const initialize = async () => {
      try {
        if (id !== "new") {
          const res = await api.get(`/post/${id}/`);
          setPostData(res.data);
          setResCode(res.status);
        }
      } catch (err) {
        setResCode(err.status);
        err.status !== 404 && apiErrAlert(err);
      }
    };

    initialize();
  }, [id]);

  return (
    <div>
      {(id === "new" && currentUser !== "") ||
      (resCode === 200 && currentUser === postData.owner) ? (
        <EditPostMain postData={postData} />
      ) : resCode === 401 ||
        resCode === 404 ||
        (postData.owner && currentUser !== postData.owner) ? (
        <Page404 />
      ) : (
        <PuffLoader color="#014421" className="mx-auto my-20" size={32} />
      )}
    </div>
  );
};

export default EditPost;
