import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import api, { apiErrAlert } from "../services/api";
import { PuffLoader } from "react-spinners";
import { dateToString } from "../utils/dateUtils";
import { LuMapPin, LuCalendarDays, LuClock } from "react-icons/lu";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Inline from "yet-another-react-lightbox/plugins/inline";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/plugins/captions.css";

import Page404 from "./Page404";
import { FaEdit, FaFacebook, FaLink } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import PostContentComponent from "../components/PostContentComponent";
import { orderImages } from "../utils/imageOrderUtils";
import { Helmet } from "react-helmet";
import { stripHTMLTags } from "../utils/htmlUtils";
import EventCard from "../components/EventCard";
import NumberNav from "../components/NumberNav";
import filler from "../assets/QAO_Logo.png";
import { toast } from "react-toastify";

const EventContent = ({ eventData, currentUser }) => {
  const stringDateStart = dateToString(eventData.start_date);
  const stringDateEnd = dateToString(eventData.end_date);
  const bannerImages = eventData.images[0]
    ? orderImages(eventData.images, eventData.image_order)
    : false;

  const [open, setOpen] = useState(false);
  const [index, setIndex] = useState(0);
  const slides = bannerImages
    ? bannerImages.map((image) => {
        return { src: image.image };
      })
    : [];
  const updateIndex = ({ index }) => {
    setIndex(index);
  };
  const copyToClipboard = () => {
    navigator.clipboard.writeText(window.location.href);
    toast.info("Link copied to clipboard!");
  };

  return (
    <div className="pb-2 mx-auto lg:w-[70%] shadow-2xl lg:rounded-xl">
      <Helmet>
        <title>{eventData.title}</title>
        <meta
          name="description"
          content={stripHTMLTags(eventData.content).slice(0, 160)}
        />
        <link
          rel="canonical"
          href={`${process.env.REACT_APP_BASE_URL}/event/${eventData.id}`}
        />
      </Helmet>

      {bannerImages && (
        <Lightbox
          slides={slides}
          index={index}
          on={{
            view: updateIndex,
            click: () => {
              setOpen(true);
            },
          }}
          inline={{
            style: {
              width: "100%",
              minHeight: "384px",
              aspectRatio: "6 / 5",
              margin: "0 auto",
            },
          }}
          thumbnails={{ vignette: false }}
          carousel={{ padding: 0, spacing: 0, finite: true }}
          plugins={[Inline, Thumbnails]}
          styles={{
            container: { backgroundColor: "rgb(243 244 246)" },
            thumbnailsContainer: { backgroundColor: "rgb(243 244 246)" },
            thumbnail: { backgroundColor: "rgb(255 255 255)" },
          }}
        />
      )}
      <Lightbox
        open={open}
        close={() => {
          setOpen(false);
        }}
        index={index}
        slides={slides}
        on={{ view: updateIndex }}
        animation={{ fade: 0 }}
        carousel={{ finite: true }}
        controller={{ closeOnBackdropClick: true, closeOnPullDown: true }}
        plugins={[Captions, Zoom]}
      />
      <h1 className="flex gap-4 justify-center items-center text-4xl py-2 lg:pt-8 px-4 sm:px-8 lg:px-16 text-center">
        {eventData.title}
        {eventData.owner === currentUser && (
          <Link to={`/event/edit/${eventData.id}`}>
            <button className="hover:text-secondary">
              <FaEdit className="min-w-9" />
            </button>
          </Link>
        )}
      </h1>
      <h2 className="text-base flex gap-1 justify-center items-center px-4 sm:px-8 lg:px-16">
        <LuMapPin /> {eventData.location}
      </h2>
      <h2 className="text-base flex gap-1 justify-center items-center px-4 sm:px-8 lg:px-16">
        <LuCalendarDays />{" "}
        {stringDateStart.date === stringDateEnd.date ? (
          `${stringDateStart.weekday}, ${stringDateStart.date}`
        ) : (
          <>
            <span className="text-center">{`${stringDateStart.weekday}, ${stringDateStart.date}`}</span>
            -
            <span className="text-center">{`${stringDateEnd.weekday}, ${stringDateEnd.date}`}</span>
          </>
        )}
      </h2>
      <h2 className="text-base flex gap-1 justify-center items-center px-4 sm:px-8 lg:px-16">
        <LuClock />{" "}
        {stringDateStart.time === stringDateEnd.time
          ? `${stringDateStart.time}`
          : `${stringDateStart.time} - ${stringDateEnd.time}`}
      </h2>
      <PostContentComponent postHtml={eventData.content} isPreview={false} />
      <div className="flex gap-4 pt-8 lg:pb-4 px-2 sm:px-4 lg:px-8">
        <h3 className="text-xl">Share this post: </h3>
        <a
          href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
            window.location.href
          )}`}
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center gap-2 bg-primary text-white py-2 px-4 rounded-full hover:bg-secondary"
        >
          <FaFacebook />
        </a>
        <a
          href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(
            window.location.href
          )}&text=${encodeURIComponent(eventData.title)}`}
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center gap-2 bg-primary text-white py-2 px-4 rounded-full hover:bg-secondary"
        >
          <FaXTwitter />
        </a>
        <button
          onClick={copyToClipboard}
          className="flex items-center gap-2 bg-primary text-white py-2 px-4 rounded-full hover:bg-secondary cursor-pointer"
        >
          <FaLink />
        </button>
      </div>
    </div>
  );
};

const Event = ({ currentUser }) => {
  const { id } = useParams();
  const [eventData, setEventData] = useState({});
  const [resCode, setResCode] = useState(0);
  const [events, setEvents] = useState([]);
  const [eventsLoading, setEventsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    api
      .get(`/event/${id}`)
      .then((res) => {
        setEventData(res.data);
        setResCode(res.status);
      })
      .catch((err) => {
        err.status !== 404 && apiErrAlert(err);
        setResCode(err.status);
      });
  }, [id]);

  useEffect(() => {
    api
      .get(`/events/?page_size=3&page=${page}`)
      .then((res) => {
        setEvents(res.data.results);
        setEventsLoading(false);
        setTotalPages(res.data.total_pages);
      })
      .catch((err) => {
        apiErrAlert(err);
      });
  }, [page]);

  return (
    <div>
      {resCode === 200 ? (
        <>
          <EventContent eventData={eventData} currentUser={currentUser} />
          <div className="p-4 sm:p-8">
            <div className="flex items-center gap-2 sm:gap-4 pb-4">
              <h1 className="text-4xl">More Events</h1>
              <hr className="border-secondary border-2 rounded-md flex-grow" />
            </div>
            <div className="grid gap-4 sm:grid-cols-2 lg:grid-cols-3 lg:gap-8">
              {eventsLoading ? (
                <PuffLoader
                  color="#014421"
                  className="m-auto col-span-3"
                  size={32}
                />
              ) : (
                events.map((event) => (
                  <EventCard
                    key={event.id}
                    targetPage={`/event/${event.id}`}
                    bannerImg={
                      event.images[0]
                        ? orderImages(event.images, event.image_order)[0].image
                        : filler
                    }
                    startDate={event.start_date}
                    endDate={event.end_date}
                    eventName={event.title}
                    location={event.location}
                    is_published={
                      currentUser === event.owner ? event.is_published : null
                    }
                  />
                ))
              )}
            </div>
            <NumberNav
              currentPage={page}
              totalPages={totalPages}
              setPageFn={setPage}
            />
          </div>
        </>
      ) : resCode === 404 ? (
        <Page404 />
      ) : (
        <PuffLoader color="#014421" className="mx-auto my-20" size={32} />
      )}
    </div>
  );
};

export default Event;
