import React, { useEffect, useState } from "react";
import { CgClose, CgAddR } from "react-icons/cg";
import { BiSolidImageAdd } from "react-icons/bi";
import { MdDeleteForever } from "react-icons/md";
import { PuffLoader } from "react-spinners";
import api, { apiErrAlert } from "../services/api";
import { toast } from "react-toastify";

const ImageSelector = ({ mode, resultHandler, initialImages = [] }) => {
  const [imageList, setImageList] = useState([]);
  const [chosenImages, setChosenImages] = useState(initialImages);
  const [uploading, setUploading] = useState(false);
  const [didDelete, setDidDelete] = useState(false);

  useEffect(() => {
    api
      .get("/images/?all")
      .then((res) => {
        setImageList(res.data);
      })
      .catch((err) => {
        apiErrAlert(err);
      });
  }, []);

  const handleChoose = (imageData) => {
    if (
      chosenImages.some((obj) => {
        return obj.id === imageData.id;
      })
    ) {
      setChosenImages(chosenImages.filter((obj) => obj.id !== imageData.id));
    } else {
      setChosenImages([...chosenImages, imageData]);
    }
  };

  const handleImageUpload = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.setAttribute("multiple", "multiple");
    input.click();
    input.onchange = () => {
      setUploading(true);
      const loadToast = toast.loading("Uploading images...");
      const files = Array.from(input.files);
      const uploadPromises = files.map((file) => {
        const formData = new FormData();
        formData.append("image", file);
        return api.post("/images/", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
      });
      Promise.all(uploadPromises)
        .then((responses) => {
          const newImages = responses.map((res) => res.data);
          setImageList([...newImages, ...imageList]);
        })
        .catch((err) => {
          apiErrAlert(err);
        })
        .finally(() => {
          setUploading(false);
          toast.update(loadToast, {
            render: "Images uploaded successfully",
            type: "success",
            isLoading: false,
            autoClose: 5000,
            closeOnClick: true,
            closeButton: true,
          });
        });
    };
  };

  const handleSave = () => {
    resultHandler(chosenImages);
  };

  const handleDelete = (image) => {
    if (window.confirm("Are you sure you want to delete this image?")) {
      api
        .delete(`/image/${image.id}/`)
        .then((res) => {
          setImageList(imageList.filter((obj) => obj.id !== image.id));
          setChosenImages(chosenImages.filter((obj) => obj.id !== image.id));
          setDidDelete(true);
          toast.success("Image deleted successfully");
        })
        .catch((err) => {
          apiErrAlert(err);
        });
    }
  };

  return (
    <div className="fixed z-10 bg-primary/50 size-full top-0 left-0 flex items-center justify-center">
      <div className="w-[95%] h-[95%] bg-primary p-4 text-white rounded-3xl shadow-2xl">
        <div className="flex justify-between mb-4">
          <h1 className="text-2xl">Select images</h1>
          <div className="flex gap-4">
            <CgClose
              size={32}
              className="cursor-pointer hover:text-secondary"
              onClick={() => {
                didDelete ? handleSave() : mode(null);
              }}
            />
            <CgAddR
              size={32}
              className="cursor-pointer hover:text-secondary"
              onClick={handleSave}
            />
          </div>
        </div>
        <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-1 overflow-scroll h-[90%]">
          <button
            className="p-4 hover:text-secondary size-full"
            onClick={handleImageUpload}
          >
            <BiSolidImageAdd size={32} className="m-auto" />
          </button>
          {uploading && (
            <PuffLoader color="#014421" className="mx-auto my-20" size={32} />
          )}
          {imageList.map((item) => {
            return (
              <div
                className="relative flex items-center justify-center"
                key={item.id}
              >
                <MdDeleteForever
                  className="absolute top-2 right-2 text-red-700 hover:text-secondary bg-white p-1 rounded-full outline"
                  size={32}
                  onClick={() => {
                    handleDelete(item);
                  }}
                />
                <img
                  src={item.image}
                  alt={item.image}
                  loading="lazy"
                  className={
                    chosenImages.some((obj) => {
                      return obj.id === item.id;
                    })
                      ? "m-auto cursor-pointer border-[5px] border-secondary"
                      : "m-auto cursor-pointer"
                  }
                  onClick={() => {
                    handleChoose(item);
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ImageSelector;
