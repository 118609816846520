import React, { useState, useEffect, useRef } from "react";
import { useParams, Navigate } from "react-router-dom";
import { BeatLoader, PuffLoader } from "react-spinners";
import { BiSolidImageAdd } from "react-icons/bi";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import ImageSelector from "../components/ImageSelector";
import Page404 from "./Page404";
import api, { apiErrAlert } from "../services/api";
import { isoToLocal, localToIso } from "../utils/dateUtils";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { orderImages } from "../utils/imageOrderUtils";
import { toast } from "react-toastify";

const EditEventMain = ({ eventData }) => {
  const [link, setLink] = useState("");
  const [title, setTitle] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [location, setLocation] = useState("");
  const [content, setContent] = useState("");
  const [images, setImages] = useState([]);
  const [isPublished, setIsPublished] = useState(false);

  const quillRef = useRef(null);
  const [submitting, setSubmitting] = useState(false);
  const [imgSelectorMode, setImgSelectorMode] = useState(null);
  const [initialImages, setInitialImages] = useState([]);
  const [startNavigate, setStartNavigate] = useState(false);

  useEffect(() => {
    if (Object.keys(eventData).length > 0) {
      setLink(`/event/${eventData.id}/`);
      setIsPublished(eventData.is_published);
      setTitle(eventData.title);
      setStartDate(isoToLocal(eventData.start_date));
      setEndDate(isoToLocal(eventData.end_date));
      setLocation(eventData.location);
      setImages(orderImages(eventData.images, eventData.image_order));
      setContent(eventData.content);
    }
  }, [eventData]);

  const handleImageUpload = (mode) => {
    if (mode === "content") {
      setInitialImages([]);
    } else if (mode === "images") {
      setInitialImages(images);
    }
    setImgSelectorMode(mode);
  };
  const modules = useRef({
    toolbar: {
      container: [
        ["bold", "italic", "underline"], // Toggle buttons for bold, italic, underline
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        [{ list: "ordered" }, { list: "bullet" }], // Lists
        ["link", "image"], // Link and image options
      ],
      handlers: {
        image: () => handleImageUpload("content"),
      },
    },
  });
  const formats = [
    "bold",
    "italic",
    "underline",
    "list",
    "bullet",
    "link",
    "image",
    "align",
  ];

  const onImgSelectorSubmit = (chosenImages) => {
    switch (imgSelectorMode) {
      case "content":
        const quill = quillRef.current.getEditor();
        quill.focus();
        const range = quill.getSelection();
        let index = range ? range.index : quill.getLength();
        chosenImages.forEach((item) => {
          quill.insertEmbed(index, "image", item.image);
          index += 1;
        });
        break;

      case "images":
        setImages([...chosenImages]);
        break;

      default:
        break;
    }
    setImgSelectorMode(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    try {
      const image_ids = images.map((image) => {
        return image.id;
      });
      const body = {
        title: title,
        start_date: localToIso(startDate),
        end_date: localToIso(endDate),
        location: location,
        content: content,
        image_ids: image_ids,
        image_order: image_ids,
        is_published: isPublished,
      };
      if (Object.keys(eventData).length > 0) {
        await api.put(`/event/${eventData.id}/`, body);
      } else {
        const res = await api.post(`/events/`, body);
        setLink(`/event/${res.data.id}`);
      }
      toast.success("Successfully saved event.");
      setStartNavigate(true);
    } catch (err) {
      apiErrAlert(err);
    } finally {
      setSubmitting(false);
    }
  };

  const handleDelete = () => {
    if (window.confirm("Are you sure you want to delete this event?")) {
      setSubmitting(true);
      api
        .delete(`/event/${eventData.id}/`)
        .then((res) => {
          console.log(res.data);
          setLink("/events/");
          toast.success("Successfully deleted event.");
          setStartNavigate(true);
        })
        .catch((err) => {
          apiErrAlert(err);
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  };

  return (
    <div className="pb-8 mx-auto lg:w-[70%] shadow-2xl relative">
      {startNavigate && <Navigate to={link} />}
      {submitting && (
        <div className="absolute z-10 size-full bg-primary/50"></div>
      )}
      {imgSelectorMode && (
        <ImageSelector
          mode={setImgSelectorMode}
          resultHandler={onImgSelectorSubmit}
          initialImages={initialImages}
        />
      )}
      <form
        className="p-4"
        onSubmit={handleSubmit}
        onKeyDown={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
      >
        <h1 className="text-center text-3xl">
          {Object.keys(eventData).length === 0 ? "Create" : "Update"} Event
        </h1>
        <div className="my-3 flex gap-1 items-center justify-center">
          <input
            type="checkbox"
            id="isPublished"
            required={false}
            checked={isPublished}
            onChange={(e) => setIsPublished(!isPublished)}
          />

          <label htmlFor="isPublished" className="text-secondary">
            Published
          </label>
        </div>
        <div className="relative z-0 my-3 w-full">
          <input
            type="text"
            id="title"
            className="block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-secondary peer"
            placeholder=" "
            required={true}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <label
            htmlFor="title"
            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 peer-focus:text-secondary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
          >
            Title <span className="text-red-500">*</span>
          </label>
        </div>
        <div className="relative z-0 my-3 w-full">
          <input
            type="datetime-local"
            id="startDate"
            className="block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-secondary peer"
            placeholder=" "
            required={true}
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
          <label
            htmlFor="startDate"
            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 peer-focus:text-secondary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
          >
            Start Date <span className="text-red-500">*</span>
          </label>
        </div>
        <div className="relative z-0 my-3 w-full">
          <input
            type="datetime-local"
            id="endDate"
            className="block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-secondary peer"
            placeholder=" "
            required={true}
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
          <label
            htmlFor="endDate"
            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 peer-focus:text-secondary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
          >
            End Date <span className="text-red-500">*</span>
          </label>
        </div>
        <div className="relative z-0 my-3 w-full">
          <input
            type="text"
            id="location"
            className="block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-secondary peer"
            placeholder=" "
            required={true}
            value={location}
            onChange={(e) => setLocation(e.target.value)}
          />
          <label
            htmlFor="location"
            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 peer-focus:text-secondary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
          >
            Location <span className="text-red-500">*</span>
          </label>
        </div>
        <div className="my-3">
          <label htmlFor="images" className="text-xs text-secondary">
            Banner Images
          </label>
          <div className="grid gap-1 text-primary">
            <button
              type="button"
              className="p-4 hover:text-secondary size-fit mx-auto flex gap-1 items-center"
              onClick={() => handleImageUpload("images")}
            >
              <BiSolidImageAdd size={32} className="m-auto" />
              <p className="text-lg">Add Images</p>
            </button>
            {images.length > 0 && (
              <Splide
                aria-label="Banner Images"
                options={{
                  type: "fade",
                  rewind: true,
                  heightRatio: 9 / 16,
                  autoplay: true,
                }}
              >
                {images.map((image) => {
                  return (
                    <SplideSlide key={image.id}>
                      <img
                        src={image.image}
                        alt={image.image}
                        className="object-fill mx-auto h-full"
                      />
                    </SplideSlide>
                  );
                })}
              </Splide>
            )}
          </div>
        </div>
        <div className="my-3">
          <label className="text-xs text-secondary">
            Content <span className="text-red-500">*</span>
          </label>
          <ReactQuill
            ref={quillRef}
            theme="snow"
            value={content}
            onChange={setContent}
            modules={modules.current}
            formats={formats}
          />
        </div>

        <div className="flex justify-evenly">
          {Object.keys(eventData).length !== 0 && (
            <button
              type="button"
              className="bg-primary hover:bg-secondary text-white font-bold p-4 rounded-xl disabled:bg-gray-400"
              disabled={submitting}
              value="save"
              onClick={handleDelete}
            >
              {submitting ? <BeatLoader className="m-auto" /> : "Delete"}
            </button>
          )}
          <button
            type="submit"
            className="bg-secondary hover:bg-primary text-white font-bold p-4 rounded-xl disabled:bg-gray-400"
            disabled={submitting}
            value="save"
          >
            {submitting ? <BeatLoader className="m-auto" /> : "Save"}
          </button>
        </div>
      </form>
    </div>
  );
};

const EditEvent = ({ currentUser }) => {
  const { id } = useParams();
  const [eventData, setEventData] = useState({});
  const [resCode, setResCode] = useState(0);

  useEffect(() => {
    const initialize = async () => {
      try {
        if (id !== "new") {
          const res = await api.get(`/event/${id}/`);
          setEventData(res.data);
          setResCode(res.status);
        }
      } catch (err) {
        setResCode(err.status);
        err.status !== 404 && apiErrAlert(err);
      }
    };

    initialize();
  }, [id]);

  return (
    <div>
      {(id === "new" && currentUser !== "") ||
      (resCode === 200 && currentUser === eventData.owner) ? (
        <EditEventMain eventData={eventData} />
      ) : resCode === 401 ||
        resCode === 404 ||
        (eventData.owner && currentUser !== eventData.owner) ? (
        <Page404 />
      ) : (
        <PuffLoader color="#014421" className="mx-auto my-20" size={32} />
      )}
    </div>
  );
};

export default EditEvent;
