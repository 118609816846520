import React, { useState, useEffect, useCallback } from "react";
import { Link, useSearchParams } from "react-router-dom";
import NumberNav from "../components/NumberNav";
import EventCard from "../components/EventCard";
import api, { apiErrAlert } from "../services/api";
import { PuffLoader } from "react-spinners";

import filler from "../assets/QAO_Logo.png";
import { orderImages } from "../utils/imageOrderUtils";
import { Helmet } from "react-helmet";

const Events = ({ currentUser }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [upcomingTotal, setUpcomingTotal] = useState(1);
  const [upcomingLoading, setUpcomingLoading] = useState(true);
  const getUpcomingPage = useCallback(() => {
    return parseInt(searchParams.get("upcomingPage")) || 1;
  }, [searchParams]);
  const setUpcomingPage = (page) => {
    searchParams.set("upcomingPage", page);
    setSearchParams(searchParams);
  };

  const [pastEvents, setPastEvents] = useState([]);
  const [pastTotal, setPastTotal] = useState(1);
  const [pastLoading, setPastLoading] = useState(true);
  const getPastPage = useCallback(() => {
    return parseInt(searchParams.get("pastPage")) || 1;
  }, [searchParams]);
  const setPastPage = (page) => {
    searchParams.set("pastPage", page);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    setUpcomingLoading(true);
    window.scrollTo(0, 0);
    api
      .get(`/events/?past=false&page=${getUpcomingPage()}`)
      .then((res) => {
        setUpcomingEvents(res.data.results);
        setUpcomingTotal(res.data.total_pages);
      })
      .catch((err) => {
        apiErrAlert(err);
      })
      .finally(() => setUpcomingLoading(false));
  }, [getUpcomingPage]);

  useEffect(() => {
    setPastLoading(true);
    window.scrollTo(0, 0);
    api
      .get(`/events/?past=true&page=${getPastPage()}`)
      .then((res) => {
        setPastEvents(res.data.results);
        setPastTotal(res.data.total_pages);
      })
      .catch((err) => {
        apiErrAlert(err);
      })
      .finally(() => setPastLoading(false));
  }, [getPastPage]);

  return (
    <div className="p-4 sm:p-8">
      <Helmet>
        <title>Events - UP Mindanao Quality Assurance Office</title>
        <meta
          name="description"
          content="Discover upcoming and past events related to UP Mindanao Quality Assurance Office. Fostering honor, excellence, and service through academic and community engagement."
        />
        <link
          rel="canonical"
          href={`${process.env.REACT_APP_BASE_URL}/events`}
        />
      </Helmet>

      {currentUser !== "" && (
        <div className="w-full flex justify-center mb-2">
          <button className="p-3 text-xl bg-primary hover:bg-secondary text-white rounded-full">
            <Link to={"/event/edit/new"}>Create new event</Link>
          </button>
        </div>
      )}
      {/* Upcoming Events Section */}
      <div className="pb-4 sm:pb-8">
        <div className="flex items-center gap-2 sm:gap-4">
          <h1 className="text-4xl">Upcoming Events</h1>
          <hr className="border-secondary border-2 rounded-md flex-grow" />
        </div>
      </div>
      <div className="grid gap-4 sm:grid-cols-2 lg:grid-cols-3 lg:gap-8">
        {upcomingLoading ? (
          <PuffLoader color="#014421" className="m-auto col-span-3" size={32} />
        ) : (
          upcomingEvents.map((event) => {
            return (
              <EventCard
                key={event.id}
                targetPage={`/event/${event.id}`}
                bannerImg={
                  event.images[0]
                    ? orderImages(event.images, event.image_order)[0].image
                    : filler
                }
                startDate={event.start_date}
                endDate={event.end_date}
                eventName={event.title}
                location={event.location}
                is_published={
                  currentUser === event.owner ? event.is_published : null
                }
              />
            );
          })
        )}
      </div>
      <NumberNav
        currentPage={getUpcomingPage()}
        totalPages={upcomingTotal}
        setPageFn={setUpcomingPage}
      />

      {/* Past Events Section */}
      <div className="pb-4 sm:pb-8">
        <div className="flex items-center gap-2 sm:gap-4">
          <h1 className="text-4xl">Past Events</h1>
          <hr className="border-secondary border-2 rounded-md flex-grow" />
        </div>
      </div>
      <div className="grid gap-4 sm:grid-cols-2 lg:grid-cols-3 lg:gap-8">
        {pastLoading ? (
          <PuffLoader color="#014421" className="m-auto col-span-3" size={32} />
        ) : (
          pastEvents.map((event) => {
            return (
              <EventCard
                key={event.id}
                targetPage={`/event/${event.id}`}
                bannerImg={
                  event.images[0]
                    ? orderImages(event.images, event.image_order)[0].image
                    : filler
                }
                startDate={event.start_date}
                endDate={event.end_date}
                eventName={event.title}
                location={event.location}
                is_published={
                  currentUser === event.owner ? event.is_published : null
                }
              />
            );
          })
        )}
      </div>
      <NumberNav
        currentPage={getPastPage()}
        totalPages={pastTotal}
        setPageFn={setPastPage}
      />
    </div>
  );
};

export default Events;
