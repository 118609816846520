import React from "react";
import { Link } from "react-router-dom";
import { LuMapPin } from "react-icons/lu";
import { dateToString } from "../utils/dateUtils";
import { LuBookOpenCheck, LuBookX } from "react-icons/lu";

const EventCard = ({
  targetPage,
  bannerImg,
  startDate,
  endDate,
  eventName,
  location,
  is_published,
}) => {
  const stringDateStart = dateToString(startDate);
  const stringDateEnd = dateToString(endDate);

  return (
    <Link
      to={targetPage}
      className="bg-white shadow-xl rounded-xl overflow-hidden hover:-mt-5 hover:mb-5 duration-300 w-full animate-jump-in"
    >
      <div className="flex flex-col h-full">
        <img
          src={bannerImg}
          alt={bannerImg.split("/").pop()}
          className="object-cover w-full h-[256px]"
          loading="lazy"
        />
        <p className="px-2 pt-2 text-gray-600">
          {stringDateStart.date === stringDateEnd.date
            ? `${stringDateStart.weekday}, ${stringDateStart.date}`
            : `${stringDateStart.weekday}, ${stringDateStart.date} - ${stringDateEnd.weekday}, ${stringDateEnd.date}`}
        </p>
        <p className="px-2 text-gray-600">
          {stringDateStart.time === stringDateEnd.time
            ? `${stringDateStart.time}`
            : `${stringDateStart.time} - ${stringDateEnd.time}`}
        </p>
        <h1 className="px-2 my-2 text-3xl line-clamp-2" title={eventName}>
          {eventName}
        </h1>
        <p className="p-2 text-gray-600 flex items-center gap-1">
          <LuMapPin className="min-w-4" />
          {location}
        </p>
        {is_published === true ? (
          <div className="flex items-center gap-1 p-2 text-secondary">
            <LuBookOpenCheck /> <p>PUBLISHED</p>
          </div>
        ) : (
          is_published === false && (
            <div className="flex items-center gap-1 p-2 text-primary">
              <LuBookX /> <p>UNPUBLISHED</p>
            </div>
          )
        )}
      </div>
    </Link>
  );
};

export default EventCard;
