import React from "react";
import {
  FaAngleLeft,
  FaAngleDoubleLeft,
  FaAngleRight,
  FaAngleDoubleRight,
} from "react-icons/fa";

const NavButton = ({ setPageFn, targetPage, displayedText }) => {
  return (
    <button
      className="text-lg rounded-full hover:underline hover:bg-primary hover:text-white p-3"
      onClick={() => {
        setPageFn(targetPage);
      }}
    >
      {displayedText}
    </button>
  );
};

const NumberNav = ({ currentPage, totalPages, setPageFn }) => {
  return (
    <div className="flex items-center justify-center text-xl">
      <NavButton
        setPageFn={setPageFn}
        targetPage={1}
        displayedText={<FaAngleDoubleLeft />}
      />
      {currentPage > 1 && (
        <NavButton
          setPageFn={setPageFn}
          targetPage={currentPage - 1}
          displayedText={<FaAngleLeft />}
        />
      )}
      <p className="p-2">
        Page {currentPage} of {totalPages}
      </p>
      {currentPage < totalPages && (
        <NavButton
          setPageFn={setPageFn}
          targetPage={currentPage + 1}
          displayedText={<FaAngleRight />}
        />
      )}
      <NavButton
        setPageFn={setPageFn}
        targetPage={totalPages}
        displayedText={<FaAngleDoubleRight />}
      />
    </div>
  );
};

export default NumberNav;
